<template>
    <div class="about">
        <h1>Carwash</h1>
        <!-- Your content here -->
    </div>
</template>

<script>
export default {
    name: 'CarwashView'
};
</script>

<style scoped>
/* Your component-specific styles here */
</style>