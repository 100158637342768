import { createApp } from 'vue';
import App from './App.vue';
import { FontAwesomeIcon } from './config/font-awesome';
import router from './router';

// Create the Vue application instance
const app = createApp(App);

// Register the FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);


// Set up the router
app.use(router);

// Mount the application
app.mount('#app');
