// src/font-awesome.js

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTwitter, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

// Add icons to the library
library.add(faInstagram, faTwitter, faLinkedin, faGithub);

export { FontAwesomeIcon };
