import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomeView.vue';
import Carwash from '../views/CarwashView.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/carwash',
        name: 'Carwash',
        component: Carwash
    }
    // Add more routes as needed
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;