<!-- src/views/Home.vue -->

<template>
    <Main />
    <ContentSection />
    <FooterSection />
</template>

<script>
import Main from '../components/MainSection.vue'
import ContentSection from '../components/ContentSection.vue'
import FooterSection from '../components/FooterSection.vue'

export default {
    name: 'HomeView',
    components: {
        Main,
        ContentSection,
        FooterSection
    }
};
</script>

<style scoped>
/* Your component-specific styles here */
</style>