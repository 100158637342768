<template>
    <div className="content">
        <div className="row">
            <div>
                <h4>HELLO PEOPLE, MY NAME IS</h4>
                <h1>David</h1>
                <h4 id="change1"></h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainSection',
    data() {
        return {
            name: 'David'
        }
    },
    mounted() {
        setInterval(() => {
            this.name = Math.random().toString(36).substring(7);
            document.getElementById('change1').innerHTML = this.name;
        }, 1000);
    }
}
</script>

<style scoped>
.content h4 {
    font-weight: 400;
    letter-spacing: 4px;
}
</style>
