<template>
    <div class="contentcontainer">
        <div v-for="(box, index) in boxes" :key="index" class="box">
            <h2 v-html="box.title"></h2>
            <p v-html="box.content"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentSection',
    data() {
        return {
            boxes: [] // Initialize empty array to hold data
        };
    },
    mounted() {
        // Simulate fetching data from a service (replace with actual API call)
        this.fetchData();
    },
    methods: {
        fetchData() {
            // Simulate API call with setTimeout (replace with actual fetch or axios call)
            setTimeout(() => {
                // Replace with actual data from service/API response
                this.boxes = [
                    {
                        title: `<h3>Integrate dependency injection into a static class</h3>`, content: `<p><b>Service Locator Pattern:</b> Easy to set up but less maintainable and testable.</p>
            <p><b>Factory Pattern:</b> More maintainable and testable, but requires additional setup.</p>
            <p><b>Manual Dependency Injection:</b> Most explicit and maintainable, but requires passing dependencies
                manually.</p>
            <a target="_blank" href="https://github.com/thedawie/ServiceInjection">
                <button class="button">Check it out</button>
            </a>` },
                    { title: "Box 2", content: "Content for Box 2" },
                    { title: "Box 3", content: "Content for Box 3" },
                    { title: "Box 3", content: "Content for Box 3" }
                ];
            }, 1000); // Simulating delay of 1 second (1000 milliseconds)
        }
    }
};
</script>

<style scoped>
.contentcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}

.box {
    background: #44627c;
    padding: 20px;
    text-align: left;
    flex-basis: calc(33.33% - 20px);
    /* 33.33% width for each box, minus margin */
    margin: 0 0 20px;
    /* Space between boxes, bottom margin to separate rows */
    border: 2px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
    /* Include padding and border in box size */
}

@media (max-width: 992px) {
    .box {
        flex-basis: calc(50% - 20px);
        /* 50% width for each box on screens <= 992px */
    }
}

@media (max-width: 600px) {
    .box {
        flex-basis: calc(100% - 20px);
        /* 100% width for each box on screens <= 600px */
    }
}
</style>