<template>
    <div class="container">
        <div class="footercentered">
            <div class="">
                <a target="_blank" href="https://www.linkedin.com/in/david-van-der-merwe-693454131/">
                    <font-awesome-icon :icon="['fab', 'linkedin']" size="2xl" style="color: #74C0FC;" />
                </a>
            </div>
            <div class="">
                <a target="_blank" href="https://www.instagram.com/thedawie/">
                    <font-awesome-icon :icon="['fab', 'instagram']" size="2xl" style="color: #ff6251;" /></a>
            </div>
            <div class="">
                <a target="_blank" href="https://twitter.com/thedawie">
                    <font-awesome-icon :icon="['fab', 'twitter']" size="2xl" style="color: #74C0FC;" />
                </a>
            </div>
            <div>
                <a target="_blank" href="https://github.com/thedawie">
                    <font-awesome-icon :icon="['fab', 'github']" size="2xl" style="color: #00364a;" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterSection'
}
</script>

<style scoped>
.footercentered {
    display: flex;
    gap: 20px;
    /* Optional: Add margin for spacing around the container */
    justify-content: space-between;
    /* Distribute the icons evenly */
    align-items: center;
    /* Center the icons vertically if needed */
    max-width: 1200px;
    /* Optional: Set a maximum width for the container */
    margin: 0 auto;
    /* Center the container horizontally */
}

.centered div {
    display: flex;
}

.footer {
    background-color: #f1f1f1;
    /* Optional: Footer background color */
    padding: 10px 0;
    /* Optional: Padding for the footer */
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footercentered div {
    flex: 1;
    /* Distribute space equally */
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    /* Center the icon within its flex item */
}
</style>